import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class ThemeSwitcherService {

  constructor(private storage: Storage) { }

  async getCurrentThemeFromStorage(){
    const storage = await this.storage.ready();
    if(storage){
      const darkMode = await this.storage.get('darkMode');
      if(darkMode){
        return true;
      }
    }
    return false;
  }

  async setInitialState(){
    const darkMode = await this.getCurrentThemeFromStorage();
    await this.setDarkMode(darkMode);
  }
  async setDarkMode(active: boolean){
    if(active){
      document.body.setAttribute('data-theme', 'dark');
      await this.storage.set('darkMode', true);
    } else {
      document.body.setAttribute('data-theme', 'light');
      await this.storage.set('darkMode', false);
    }
  }

  async changeTheme(event){
    if(event.detail.checked){
      await this.setDarkMode(true);
    }
    else{
      await this.setDarkMode(false);
    }
  }
}
